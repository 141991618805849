import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';

export const DashboardGuard: CanActivateFn = (route) => {
	const router = inject(Router);

	if (route.queryParamMap.get('showOnboarding') === 'true') {
		return router.navigate(['/event/create']);
	}

	return true;
};
