import {
	afterNextRender,
	ChangeDetectionStrategy,
	Component,
	inject,
	OnInit,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NavigationComponent } from '@core/components/navigation/navigation';
import { AuthService } from '@core/services/auth';
import { ConfigService } from '@core/services/config';
import { AsyncPipe } from '@angular/common';
import { Performance } from '@angular/fire/performance';
import { HlmToasterComponent } from '@spartan-ng/ui-sonner-helm';
import { Analytics } from '@angular/fire/analytics';
import { Platform } from '@angular/cdk/platform';
import { SeoService } from '@core/services/seo';
import { environment } from '@env/environment';

@Component({
	selector: 'eventframe',
	standalone: true,
	imports: [RouterOutlet, NavigationComponent, AsyncPipe, HlmToasterComponent],
	host: {
		class: 'flex flex-col h-screen',
	},
	template: `
		@if (config$ | async; as config) {
			@if (!config?.hideNav && isAuthenticated()) {
				<navigation />
			}

			<router-outlet />

			@if (isBrowser) {
				@defer (on idle) {
					<hlm-toaster position="top-center" />
				}
			}
		}
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
	// NOTE: this must be injected to make performance monitoring work!
	private perf = inject(Performance);
	private analytics = inject(Analytics);
	private seo = inject(SeoService);

	config$ = inject(ConfigService).getConfig();
	isAuthenticated = inject(AuthService).isAuthenticated;
	isBrowser = inject(Platform).isBrowser;

	constructor() {
		afterNextRender(() => {
			if (this.isBrowser) {
				document.getElementById('loader')?.remove();
			}
		});
	}

	ngOnInit() {
		this.config$.subscribe((config) => {
			if (config.canonical) {
				this.seo.updateCanonicalUrl(
					`${environment.baseUrl}/${config.canonical}`
				);
			} else {
				this.seo.removeCanonical();
			}
		});
	}
}
