import {
	APP_INITIALIZER,
	ApplicationConfig,
	provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import {
	provideRouter,
	Router,
	withComponentInputBinding,
	withInMemoryScrolling,
	withViewTransitions,
} from '@angular/router';
import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '@env/environment';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import {
	getAnalytics,
	provideAnalytics,
	ScreenTrackingService,
} from '@angular/fire/analytics';
import { TraceService } from '@sentry/angular';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { provideTrpcClient } from '@core/services/trpc';
import { provideHttpClient } from '@angular/common/http';

export const appConfig: ApplicationConfig = {
	providers: [
		provideExperimentalZonelessChangeDetection(),
		provideRouter(
			routes,
			withComponentInputBinding(),
			withViewTransitions({
				skipInitialTransition: true,
			}),
			withInMemoryScrolling({
				scrollPositionRestoration: 'enabled',
				anchorScrolling: 'enabled',
			})
		),
		provideAnimationsAsync(),
		provideFirebaseApp(() => initializeApp(environment.firebase)),
		provideAuth(() => getAuth()),
		providePerformance(() => getPerformance()),
		provideAnalytics(() => getAnalytics()),
		provideTrpcClient(),
		provideHttpClient(),
		ScreenTrackingService,
		{
			provide: TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {
				//
			},
			deps: [TraceService],
			multi: true,
		},
	],
};
