import { inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';

@Injectable({
	providedIn: 'root',
})
export class SeoService {
	private dom = inject(DOCUMENT);
	isBrowser = inject(Platform).isBrowser;

	updateCanonicalUrl(url: string): void {
		if (!this.isBrowser) {
			return;
		}

		const head = this.dom.getElementsByTagName('head')[0];
		let element = this.dom.querySelector(`link[rel='canonical']`) || null;
		if (element == null) {
			element = this.dom.createElement('link') as HTMLLinkElement;
			head.appendChild(element);
		}
		element.setAttribute('rel', 'canonical');
		element.setAttribute('href', url);
	}

	removeCanonical(): void {
		if (!this.isBrowser) {
			return;
		}

		const element = this.dom.querySelector(`link[rel='canonical']`) || null;
		if (element != null) {
			element.remove();
		}
	}
}
