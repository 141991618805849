import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { browserTracingIntegration, init } from '@sentry/angular';
import { environment } from '@env/environment';

init({
	dsn: 'https://3b57a3e11e6f3454dd511ca8f723e41f@o4507177607168000.ingest.de.sentry.io/4507186983403600',
	enabled: environment.production,
	integrations: [browserTracingIntegration()],
	tracesSampleRate: 1.0,
	tracePropagationTargets: [
		'https://eventframe.app',
		'https://www.eventframe.app',
	],
});

bootstrapApplication(AppComponent, appConfig).catch((err) =>
	console.error(err)
);
