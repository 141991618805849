import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AuthService } from '@core/services/auth';
import { radixDashboard } from '@ng-icons/radix-icons';
import { HlmButtonDirective } from '@spartan-ng/ui-button-helm';
import { HlmIconComponent, provideIcons } from '@spartan-ng/ui-icon-helm';
import { BrnMenuTriggerDirective } from '@spartan-ng/ui-menu-brain';
import {
	HlmMenuComponent,
	HlmMenuGroupComponent,
	HlmMenuImports,
	HlmMenuItemDirective,
	HlmMenuItemIconDirective,
	HlmMenuSeparatorComponent,
} from '@spartan-ng/ui-menu-helm';
import {
	lucideLogOut,
	lucideSettings,
	lucideUser,
	lucideUserCog,
} from '@ng-icons/lucide';
import { AsyncPipe, NgOptimizedImage } from '@angular/common';
import { ThemeService } from '@core/services/theme';

@Component({
	selector: 'navigation',
	standalone: true,
	providers: [
		provideIcons({
			lucideLogOut,
			radixDashboard,
			lucideUser,
			lucideSettings,
			lucideUserCog,
		}),
	],
	imports: [
		HlmMenuImports,
		BrnMenuTriggerDirective,
		RouterLink,
		HlmIconComponent,
		HlmButtonDirective,
		HlmMenuComponent,
		HlmMenuGroupComponent,
		HlmMenuItemDirective,
		HlmMenuItemIconDirective,
		HlmMenuSeparatorComponent,
		NgOptimizedImage,
		AsyncPipe,
	],
	host: {
		class:
			'block sticky w-full top-0 z-40 bg-background/95 bg-blur-lg border-b border-border p-2 md:px-8 lg:px-10',
	},
	template: `
		<div class="flex flex-row justify-center">
			<nav class="flex w-full max-w-screen-xl items-center justify-between">
				<button hlmBtn size="icon" variant="ghost" routerLink="/dashboard">
					<img
						alt="Eventframe icon"
						width="24"
						height="24"
						[ngSrc]="
							(theme.theme$ | async) === 'dark'
								? 'assets/favicons/favicon.svg'
								: 'assets/favicons/favicon-dark.svg'
						"
					/>
				</button>

				<div class="flex gap-5">
					<button [brnMenuTriggerFor]="menu" hlmBtn size="icon" variant="ghost">
						<hlm-icon name="lucideUser" />
					</button>

					<ng-template #menu>
						<hlm-menu>
							<hlm-menu-group>
								<button routerLink="/settings" hlmMenuItem>
									<hlm-icon name="lucideSettings" hlmMenuIcon />
									<span>Settings</span>
								</button>
							</hlm-menu-group>

							<hlm-menu-separator />

							<hlm-menu-group>
								<button (click)="auth.logout()" hlmMenuItem>
									<hlm-icon name="lucideLogOut" hlmMenuIcon />
									<span>Log Out</span>
								</button>
							</hlm-menu-group>
						</hlm-menu>
					</ng-template>
				</div>
			</nav>
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent {
	auth = inject(AuthService);
	theme = inject(ThemeService);
}
